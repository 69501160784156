<template>
    <div class="industry_trends_child_en_div">
        <div class="nav_div"></div>
        <div class="industry_trends_content_div allContentWidth">
            <div class="return_box">
                <span @click="backTopPage"><< Return to list of news events</span>
            </div>
            <div class="industry_trends_content_box">
                <p class="title_p">{{ contentObj.title }}</p>
                <div class="time_from_div">
                    <span class="time_span">{{ contentObj.time }}</span>
                    <span v-if="contentObj.from" class="from_span">From：{{ contentObj.from }}</span>
                </div>
                <div class="paragraph_div">
                    <p v-for="item in contentObj.content" :key="item.id">{{ item.text }}</p>
                </div>
                <div class="news_img_div">
                    <div v-for="a in contentObj.img_url">
                        <img v-if="a.height" :style="'height:'+a.height" :src="a.url"/>
                        <img v-if="!a.height" :src="a.url"/>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    components: {},
    computed: {},
    mounted() {
        this.initData();
    },
    watch: {},
    methods: {
        backTopPage() {
            this.$router.push(`/news_en`);
        },
        initData() {
            this.contentAll.map((item) => {
                if (item.key === Number(this.$route.params.id)) {
                    this.contentObj = item;
                }
            })
        },
    },
    data() {
        var imgSrc = [];
        imgSrc.push(require('../../assets/img/aboutUs/about_banner.png'));//(0)
        return {
            imgSrc: imgSrc,
            contentObj: {},
            contentAll: [
                {
                    key: 1,
                    title: 'Drewry: Surplus of Six Million TEU Built Up by Carriers Will Recede',
                    time: 'PUBLISHED JUL 14, 2022 7:43 PM',
                    from: 'BY THE MARITIME EXECUTIVE',
                    content: [
                        {
                            id: 1,
                            text: 'As the surge in container shipping grew in 2020 and 2021 carriers around the world were scrambling to find enough boxes to accommodate shipments while container manufacturers ramped up production to record levels. A new market analysis by the research and consulting service Drewey points to a near-term surplus of containers, a position supported by many ports that are complaining that their yards are being used to store boxes'
                        },
                        {
                            id: 2,
                            text: 'According to Drewry’s recently published Container Census & Leasing Annual Review & Forecast 2022/23 report, the global pool of shipping containers increased by 13 percent to almost 50 million TEU in 2021. That was three times the prior growth trend and in addition, they report that lessors and ocean carriers were retiring fewer aging units in a further effort to rapidly build the supply. '
                        },
                        {
                            id: 3,
                            text: 'Yet despite the increase in supply, carriers continued to experience bottlenecks throughout the supply chain that meant containers were taking longer to return to the ships and back to manufacturing centers. Drewry in its new report estimated that congestion across global supply chains meant containers were an estimated 15 to 20 percent less productive than in pre-Covid-19 times.'
                        },
                        {
                            id: 4,
                            text: 'China, which is the largest manufacturer of containers, reported in the fourth quarter of 2020 they had increased container output to 300,000 TEU per month. Chinese container manufacturers had extended their normal working hours from eight hours to 11 hours a day.'
                        },
                        {
                            id: 5,
                            text: 'As a result, Drewry now estimates that as many as six million TEU of surplus containers exist in the global equipment pool. While large by historic standards, Drewry considers this surplus to be manageable in the longer term for the industry. However, near-term ports continue to complain that boxes are piling up in their yards. In addition to importers being slow to move boxes from the yards, empties have been stacking up. '
                        },
                        {
                            id: 6,
                            text: 'Drewry forecasts for the equipment market however project that the surplus will begin to decline. They write that output in 2022 and 2023 will be much lower than last year, at 3.9 million TEU this year and 2.4 million TEU next year, with replacement accounting for most of the orders. While they forecast newbuild and second-hand prices will fall, a return to the very low prices of 2019 is not anticipated as manufacturers are expected to manage their capacity and pricing strategies very carefully. Meanwhile, the secondary market remains robust and the uses to which ex-trading containers can be put to use continue to expand.'
                        },
                        {
                            id: 7,
                            text: '"Looking ahead, ocean carriers will be the main buyers of equipment over the next two years with lessors then taking control again, raising their share of the pool to 54 percent by 2026," said Drewry’s head of container equipment research John Fossey. "Moreover, per diem rates and investment cash returns will generally be higher over the forecast period than in the past five years."'
                        },
                        {
                            id: 8,
                            text: 'Drewry also highlights the coming surge in containership deliveries over the next few years as orders are fulfilled from the record pace of containership construction contracts. "The delivery schedule of new ships is very strong with slot capacity expected to increase by 3.6 million TEU in 2023 and by over 3.9 million TEU in 2024," says Fossey'
                        },
                        {
                            id: 9,
                            text: 'Also helping to absorb the current surplus capacity, Drewry points to the new IMO emissions regulations coming into force in January 2023 which could result in ships reducing speed to meet the requirements.  Finally, after being caught short in 2020 and 2021, Drewry expects that some carriers are likely to expand their buffer stock in their equipment pools in an effort not to repeat the shortages they experienced.'
                        }
                    ]
                },
                {
                    key: 2,
                    title: 'HMM Plans $11 Billion Investment for Growth and Diversification',
                    time: 'PUBLISHED JUL 14, 2022 1:45 PM',
                    from: 'BY THE MARITIME EXECUTIVE',
                    content: [
                        {
                            id: 1,
                            text: 'South Korea’s largest container shipping company HMM has become the latest carrier to announce growth plans fueled by the two-year surge in the container shipping industry. The company detailed a five-year strategy calling for $11.4 billion in investments both responding to “uncertainty arising from ever-changing business circumstances,” while also laying the foundation for sustainable growth. '
                        },
                        {
                            id: 2,
                            text: 'The shipping company, which is currently the eighth largest container carrier, said that it intends to invest in a range of initiatives cutting across core assets such as ships, terminals. and logistics facilities while also focusing on the issues confronting the shipping industry including digitalization and decarbonization. The five-point strategy is seen as the next step in the rehabilitation of the former Hyundai Merchant Marine, which in 2016 was separated from the Hyundai Group and rescued by the government-owned Korean Development Bank. KDB along with the state-run Korea Ocean Business Corp. currently own a total of more than 40 percent of the shipping line with reports that they were looking for a strategy as part of plans to begin privatizing the company in 2022.'
                        },
                        {
                            id: 3,
                            text: '“Our strategy is to ensure perpetual growth of HMM under the new vision - a global leading company generating sustainable value for the world,” said Kim, Kyung Bae, HMM President and CEO. Speaking to reporters in South Korea he said they were not currently discussing the timing and strategy for the privatization. He commented, “If we make the company stronger, its fundamentals will improve and enhance shareholder value.”'
                        },
                        {
                            id: 4,
                            text: 'HMM’s five-year growth strategy focuses on five key business elements, with the majority of the investment for new vessels as well as terminals and logistic facilities. Currently deriving 90 percent of its income from the segment, HMM said it will nearly double container capacity to 1.2 million TEU from the current 820,000 TEU. The company also plans to expand its bulk fleet from the current 29 ships to 55 ships, which would increase its capacity by 90 percent.'
                        },
                        {
                            id: 5,
                            text: 'The total investment planned for ships as well as for the acquisition of terminals and logistics facilities is $7.8 billion. The strategy calls for the investment to be completed by 2026. As part of the effort, they will focus on the environment including possibly ordering new ships using alternative fuels and R&D efforts to support the goal of reaching carbon neutrality in 2050.'
                        },
                        {
                            id: 6,
                            text: 'HMM also plans to continue its investments in its digital strategy. The company recently launched an online sales platform for customers. In the next steps, they plan to integrate their inland logistics network and apply freight rate solutions using artificial intelligence. HMM plans to invest $114 million in digitalization by improving its online-based platform and accelerating ERP upgrades.'
                        },
                        {
                            id: 7,
                            text: 'Without providing further details, HMM said it will also invest $3.8 billion over the next five years in future strategic projects to diversify its business.'
                        },
                        {
                            id: 8,
                            text: 'The investment plans come at a time when HMM is on a steady recovery trajectory after the Korean government made a debt deal to save the company from bankruptcy in 2016. HMM rebounded in 2021 reporting $11.5 billion in revenues and $4.6 billion in net profit. For much of 2021, there was speculation that the government was seeking a buyer. '
                        },
                    ]
                },
                {
                    key: 3,
                    title: 'Berge Bulk Adds Rotor Sails to Two Giant Bulkers',
                    time: 'PUBLISHED JUL 13, 2022 8:33 PM',
                    from: 'BY THE MARITIME EXECUTIVE',
                    content: [
                        {
                            id: 1,
                            text: 'Singapore-based dry bulk owner Berge Bulk is accelerating its use of wind-assisted propulsion technology by contracting with Anemoi Marine Technologies to supply rotor sails for two vessels in its fleet.'
                        },
                        {
                            id: 2,
                            text: 'Just days after Berge Bulk agreed to equip its Newcastlemax bulker Berge Olympus with BAR Tech WindWings, supplied by Yara Marine Technologies, the company announced that Anemoi Marine will supply wind-assisted propulsion technology for two of its bulkers.'
                        },
                        {
                            id: 3,
                            text: 'UK-based Anemoi Marine builds rotor sail propulsion systems for commercial vessels, a technology that is fast gaining traction as the global maritime industry pursues a lower-carbon future. Anemoi and competitor Norsepower have a growing number of vessel references as shipowners look for new ways to save on fuel and reduce emissions.'
                        },
                        {
                            id: 4,
                            text: 'According to Berge Bulk, Berge Neblina - a 388,000 dwt Valemax ore carrier built in 2012 - was made "wind-ready" earlier this year with the structural integration required prior to installing the rotors. The work was carried out during a scheduled drydocking.'
                        },
                        {
                            id: 5,
                            text: 'Four of Anemoi’s large folding deployment rotor sails will be installed to improve vessel performance. Folding rotor sails can be lowered from the vertical to mitigate the impact on air draught and cargo handling operations.'
                        },
                        {
                            id: 6,
                            text: 'The flexible ‘wind-ready’ approach has been taken to align with vessel availability and Anemoi’s production slots. The same approach has been taken with the second vessel, Berge Mulhacen, a 2017-built 210,000 dwt Newcastlemax bulker which will also receive four folding rotor sails. Plan approval has been obtained for both ships from DNV.'
                        },
                        {
                            id: 7,
                            text: 'Anemoi predicts that the four-rotor system will save Berge Bulk 1,200-1,500 tonnes of fuel per vessel annually.'
                        },
                        {
                            id: 8,
                            text: '“Wind propulsion is an option we have explored previously in other formats, and we firmly believe it can help achieve our decarbonization commitments,” said Paolo Tonon, Berge Bulk Technical Director.'
                        },
                        {
                            id: 9,
                            text: 'He added that the partnership with Anemoi commenced with in-depth engineering simulations to find the best possible technical and commercial solution culminating in the rolling out of the rotor sail technology for the two vessels.'
                        },
                        {
                            id: 10,
                            text: 'Berge Bulk, which owns and manages a fleet of over 80 vessels equating to more than 14 million dwt, believes that investing in wind-assisted propulsion technology will cement its position in the dry bulk industry in terms of efficient ship design and operations and forms part of its committed to developing and deploying commercially viable deep-sea zero-emission vessels by 2030. The company has committed to be carbon neutral by 2025 at the latest.'
                        },
                        {
                            id: 11,
                            text: '“Forward-thinking shipowners are turning to wind-assisted propulsion to help them achieve their environmental objectives – and it proves, once again, that rotor sails are a realistic and workable solution that results in significant carbon savings,” said Kim Diederichsen, Anemoi Marine CEO.'
                        },
                        {
                            id: 12,
                            text: 'Wind-assisted propulsion has emerged as a strong contender as the maritime industry explores decarbonization options to achieve the International Maritime Organization’s greenhouse gases reduction targets for 2030 and 2050. '
                        },
                        {
                            id: 13,
                            text: 'To date, most of the shipping industry’s installations of wind-assisted propulsion have focused on Flettner rotor technology with several companies attaining meaningful fuel savings and reductions in emissions.'
                        }
                    ]
                },
                {
                    key: 4,
                    title: 'Are Spot Rates an Effective Metric for Ocean Freight?',
                    time: 'PUBLISHED JUL 13, 2022 3:27 PM',
                    from: 'BY THE MARITIME EXECUTIVE',
                    content: [
                        {
                            id: 1,
                            text: 'The news of declining spot rates is a welcome development among shippers, whose wallets have been lightened by soaring freight costs over the last year. The limelight is on the transpacific trade route, where the spot rates have now fallen below the contract rates signed in late 2021 and early 2022.'
                        },
                        {
                            id: 2,
                            text: 'Whereas the decline should be seen as a positive trend for shippers, most analysts contend it is still early to celebrate.'
                        },
                        {
                            id: 3,
                            text: '“This is the noisiest and most confusing micro/macro environment any of us have seen at this point of any container shipping year. It is very unclear of how things are going to unfold, but it is clear that the next 2- 6 weeks will be quite pivotal,” commented Eric Johnson, Director at S&P Global Market Intelligence.'
                        },
                        {
                            id: 4,
                            text: 'Since the pandemic started, spot rates have been used as an important benchmark to model the chaotic container-shipping scene. The seesawing of spot rates against long-term contract rates is a constant concern for many supply chain professionals.'
                        },
                        {
                            id: 5,
                            text: 'In a recent blog post, John McCown, a renowned shipping expert and founder of Blue Alpha Capital, takes issue with widespread use of spot rates as a metric to predict changes within the shipping industry.'
                        },
                        {
                            id: 6,
                            text: '“Based on a review of data, informed by experience as an operator and investor in the container sector, we see the prevalence and credibility of spot rates are broadly misunderstood. As a result, we see this leading to confusion and uninformed decisions at best and manipulation and bad decisions at worst,” wrote McCown.'
                        },
                        {
                            id: 7,
                            text: 'While the spot rates may have relevance directionally for a relatively small group of loads, the disparity among various spot indices suggests that they are not a refined measure. Spot rates do not reflect the level and trend of the larger category of contract rates, which drive both the inflation impact of shipping cost and the profitability of the container-shipping sector.'
                        },
                        {
                            id: 8,
                            text: '“Spot rates may well be the three card monte of container shipping,” remarked McCown.'
                        },
                        {
                            id: 9,
                            text: 'The crux of this discussion is to propose a solution that ideally befits the dynamics of the shipping industry. According to McCown, regular public filings by industry groups and individual container carriers of factual historical information should cure the problem.'
                        },
                        {
                            id: 10,
                            text: 'Take for instance, when new contract terms are being discussed, trade lane spot rates that present a favorable comparison for the carrier are highlighted. Shippers will often agree to the rates that appear to give them preferential treatment while in fact those rates are higher than most of the carrier’s other contracts. One side has all the facts while the other side is operating with an impaired vision.'
                        },
                        {
                            id: 11,
                            text: 'To give a context of how the shipping industry can provide more actual pricing clarity, McCown cites the model of the Securities Exchange Commission (SEC), which acts as a clearinghouse for filing detailed and comprehensive information that allows investors to make their own judgement.'
                        },
                        {
                            id: 12,
                            text: 'In the case of the shipping sector, regulators such as the Federal Maritime Commission (FMC) could also become more of clearinghouses for detailed factual information, readily available on their websites for shippers and the public at large.'
                        },
                        {
                            id: 13,
                            text: 'The recent Ocean Shipping Reform Act (OSRA), which came into law in June in the US, has a provision requiring additional disclosures by carriers. This will be in form of quarterly report published by FMC on its website showing total import and export tonnage and the total loaded and empty TEU per vessel making port calls in US. '
                        },
                    ]
                },
                {
                    key: 5,
                    title: 'Shipping’s Next Fuel of Choice Could be High Sulfur Fuel Oil',
                    time: 'PUBLISHED JUL 13, 2022 3:01 PM',
                    from: 'BY BRIAN GICHERU KINYUA',
                    content: [
                        {
                            id: 1,
                            text: 'The ongoing global oil crunch has also hit the shipping industry. In June, VLSFO (Very Low Sulphur Fuel Oil) prices skyrocketed at major bunkering hubs in the world recording a stark difference to HSFO (High Sulphur Fuel Oil) prices.'
                        },
                        {
                            id: 2,
                            text: 'Most important was the record price differential between HSFO and VSLFO, which nearly crossed $500 per ton. In fact, HSFO prices have been declining, partially driven by increased Russian flows. On the other hand, the cost of VSLFO has surged as more feedstock is refined into gasoline.'
                        },
                        {
                            id: 3,
                            text: 'The prospect could not be more appealing for ships already fitted with scrubbers. In fact, with indications showing that fuel crisis might persist in coming months, some analysts predict the current HSFO price discount might prompt more ship owners to install scrubbers, which roughly costs $2 million - $5 million depending on the size of a ship. Shipowners with large vessels that consume a lot of VLSFO might lead the trend.'
                        },
                        {
                            id: 4,
                            text: 'Scrubbers rose into prominence in 2020 after IMO started to implement a new global sulfur cap of 0.5 percent, reduced from the 3.5 percent standard for bunker fuel. To meet the target, the global shipping fleet had to switch to low-sulphur fuel (VLSFO).'
                        },
                        {
                            id: 5,
                            text: 'However, ships with scrubbers can run on cheaper HSFO, which typically has 2-3 percent sulfur content.'
                        },
                        {
                            id: 6,
                            text: 'According to estimates by Drewry, some 19 percent of all container ships are fitted with scrubbers, the highest proportion among different types of ships, followed by tankers and break carriers at around 13 percent.'
                        },
                        {
                            id: 7,
                            text: 'If the prediction of more scrubbers getting into the market is accurate, some environmental campaigners are concerned that the shipping industry would be solving the problem of air pollution at the expense of the ocean.'
                        },
                        {
                            id: 8,
                            text: 'Scrubbers, located in the exhaust stacks of ships, mainly use seawater to spray or “scrub” sulphur contaminants from the engine’s exhaust. Most vessels use an open-loop system where, instead of the water being stored in a holding tank for later disposal at dedicated port facilities, the ships dump the acidic wash into the ocean.'
                        },
                        {
                            id: 9,
                            text: 'The International Council on Clean Transportation (ICCT) estimates that 10 gigatonnes of scrubber washwater is discharged into the oceans annually.'
                        },
                        {
                            id: 10,
                            text: 'But investing in scrubbers to enjoy discounts with cheap HSFO is characteristic of the long way shipping industry has to go in cutting off dependence on fossil fuels. Although there are immense efforts by shipping stakeholders to transition to cleaner fuels, multiple challenges still abound.'
                        },
                        {
                            id: 11,
                            text: 'In a recent interview with CNBC, Maersk decarbonization chief Morten Bo Christiansen noted that although the company had ordered 13 ships to run on green methanol, the vessels will arrive prior to the fuels, which is of course not ideal.'
                        },
                        {
                            id: 12,
                            text: '“It is a chicken and egg situation. When we ordered the vessels, there was no supplier (of the fuel) whatsoever. One of the points of ordering the vessels was actually to put a demand signal in the market so that fuel production will start,” said Christiansen.'
                        },
                        {
                            id: 13,
                            text: 'Beginning next year, IMO will require ships to calculate their annual carbon intensity and show that it is progressively falling. With uncertainty persisting on which clean fuels will be used in the long-term, most ships will be left with the option of moving slower. Studies show that a 10 percent drop in cruising speeds could slash fuel usage by up to 30 percent.'
                        },
                        {
                            id: 14,
                            text: 'Currently, only about five percent of the world’s fleet runs on clean fuels, although more than 40 percent of new ship orders have an option for clean fuel, according to data from Clarksons Research.'
                        },
                    ]
                },
                {
                    key: 6,
                    title: 'New Bill Would Empower EPA to Regulate CO2, But Only From Ships',
                    time: 'PUBLISHED JUL 12, 2022 9:41 PM',
                    from: 'BY THE MARITIME EXECUTIVE',
                    content: [
                        {
                            id: 1,
                            text: 'The U.S. Supreme Court may have undercut the federal government’s ability to regulate carbon dioxide emissions, but a new bill from Reps. Alan Lowenthal and Nanette Barragán (D-CA) would restore its authority - for one industry.'
                        },
                        {
                            id: 2,
                            text: 'The proposed Clean Shipping Act of 2022 is the first purpose-built legislation to target shipping’s greenhouse gas emissions in the United States, and it is modeled on the European Union’s Fit for 55 regulatory framework for shipping. If enacted as written, it would be among the few clear authorities the EPA could draw upon to regulate carbon emissions from any industry. '
                        },
                        {
                            id: 3,
                            text: '“We no longer have the luxury of waiting to act,” Congressman Lowenthal said in a statement. “We must face the fact that we are at a tipping point in the climate crisis; we must move beyond fossil fuels, and that includes air, land and sea transportation sources."'
                        },
                        {
                            id: 4,
                            text: 'The bill would require the EPA to enforce new fuel carbon-intensity standards for commercial voyages, international and domestic, involving any "U.S. ports of call." The greenhouse gas intensity reduction would ratchet quickly down: 20 percent less in 2027, 45 percent less in 2030, 80 percent less in 2035 and 100 percent less in 2040, matching Maersk’s ambitious zero-by-2040 target.'
                        },
                        {
                            id: 5,
                            text: 'The low-carbon fuel requirement would only apply to ships that spend 30 days a year or more on voyages to and from U.S. ports. This would cover the domestic Jones Act fleet, most of the U.S.-flag fleet and foreign-flag ships on liner routes. However, some foreign-flag owners could attain compliance by keeping a hull’s annual "covered voyage" under 29 days, or by using a legitimate cargo stop in Mexico or Canada to "shorten" the length of each overseas voyage to the United States. Similar itineraries have long been used in both the cargo and passenger-vessel sectors for cabotage compliance.'
                        },
                        {
                            id: 6,
                            text: 'Vessels under 400 GT, like most tugs, towboats, and ATB pusher tugs, would not be regulated. This would allow towing operators to continue using fossil fuels, even for tows with greater capacity than a coastal freighter or a small product tanker.'
                        },
                        {
                            id: 7,
                            text: 'As they are not covered by the regulation, trucking operators, rail lines and air cargo companies - which compete with shipping and emit more per ton-mile - could continue to operate using fossil fuels.'
                        },
                        {
                            id: 8,
                            text: 'The bill’s language would also add a layer to U.S. maritime regulation by targeting only voyages to and from "U.S. ports of call," leaving out other places where commercial vessels often go. The exemption or inclusion of other U.S. destinations - rigs, platforms, offshore wind towers, anchorages, ship-to-ship transfer areas, fishing grounds - could have significant and complex implications for vessel operators. '
                        },
                    ]
                },
                {
                    key: 7,
                    title: 'SHANGHAI PORT REMAIN NO.1 IN 2022',
                    time: '2023.01',
                    content: [
                        {
                            id: 101,
                            text: 'Shanghai Port remained the world\'s busiest container port in 2022, although noting only marginal growth year-on-year due to Covid-19-related disruptions at the major Chinese port.'
                        },
                        {
                            id: 102,
                            text: 'State media reported that the port\'s container throughput exceeded 47.3 million Twenty-foot equivalent units (TEUs) last year, citing data from port operator Shanghai International Port (Group) Co., Ltd.'
                        },
                        {
                            id: 103,
                            text: '"Shanghai Port remained the world\'s busiest container port for the 13th straight year in 2022, despite the COVID-19 epidemic," Xinhua reported.'
                        },
                        {
                            id: 104,
                            text: 'It added that following the epidemic in the second quarter, the port managed to "quickly make a V-shaped recovery" in container throughput in July when it handled a monthly record high of 4.3 million TEUs.'
                        },
                        {
                            id: 105,
                            text: 'Nonetheless, the 2022 performance of the port was only slightly higher than the 47 million TEUs recorded at Shanghai Port in 2021.'
                        },
                        {
                            id: 106,
                            text: 'As of November, the port\'s closest rival is the Port of Singapore which recorded a throughput of 34 million TEUs between January-November 2022.'
                        },
                    ],
                    img_url: [{url: require('../../assets/img/home_hnews7_1.png'), height: '415px;'},
                        {url: require('../../assets/img/home_hnews7_2.png')}]
                },
                {
                    key: 8,
                    title: 'China\'s railway cargo delivery volume reached 3.9 billion tonnes in 2022, up 4.7% year on year.',
                    time: '2023.01',
                    content: [
                        {
                            id: 101,
                            text: 'Chinese state media said railway cargo services raked in 478.6 billion yuan (about US$69.23 billion) last year, rising 9.8% over 2021, citing data from the China State Railway Group Co., Ltd.'
                        },
                        {
                            id: 102,
                            text: 'Last year, China saw some 4,100 km of new railway lines put into operation in 2022, and over half of them were high-speed railways.'
                        },
                        {
                            id: 103,
                            text: '"This year, the national railway operator expects passenger trips to climb over 67% from that of 2022 and estimates that overall transportation revenue will generally rebound to the level before the epidemic," the report added.'
                        },
                        {
                            id: 104,
                            text: 'Over 3,000 km of new railway lines will be put into service in 2023, with 2,500 km being high-speed railways.'
                        },
                        {
                            id: 105,
                            text: 'Meanwhile, Xi\'an, the capital of northwest China\'s Shaanxi Province, saw a surge in China-Europe freight-train trips in 2022.'
                        },
                        {
                            id: 106,
                            text: 'Xinhua said the Chang\'an China-Europe freight-train service operated 4,639 train trips last year, up 20.8% year on year, and transported nearly 4.12 million tonnes of goods, an annual increase of 43.3%.'
                        },
                        {
                            id: 107,
                            text: 'The city ranked first among Chinese cities operating the China-Europe freight service for the number of train trips, the cargo volume and the rate of heavy containers in 2022.'
                        },
                        {
                            id: 108,
                            text: 'Since its launch in 2013, the Chang\'an service has opened 17 cargo routes linking Xi\'an with destinations in Asia and Europe and has handled a total of 16,054 train trips. '
                        },
                    ],
                    img_url: [{url: require('../../assets/img/home_hnews8_1.png'), height: '500px;'},
                        {url: require('../../assets/img/home_hnews8_2.png'), height: '250px;'}]
                },
                {
                    key: 9,
                    title: 'Blockbuster Trade',
                    time: '2023.05',
                    content: [
                        {
                            id: 101,
                            text: 'Hapag-Lloyd announced that it successfully completed its acquisition of 40% shareholding in India\'s J M Baxi Ports & Logistics Limited (JMBPL) previously approved by the relevant antitrust authority.'
                        },
                        {
                            id: 102,
                            text: 'In a statement, the shipping giant said that in January 2023, it had made an agreement to this effect with an affiliate of Bain Capital Private Equity, which held a 35% stake in JMBPL, and the Kotak family, which holds a majority of the shares of JMBPL.'
                        },
                        {
                            id: 103,
                            text: 'In driving its Strategy 2023, Hapag-Lloyd noted that it has continuously expanded its involvement in the terminal sector, inter alliance through an agreement to acquire the terminal business of Chile-based SM SAAM.'
                        },
                        {
                            id: 104,
                            text: 'In addition, Hapag-Lloyd also holds stakes in the Italy-based Spinelli Group, Container Terminal Wilhelmshaven, the Container Terminal Altenwerder in Hamburg, Terminal TC3 in Tangier, and Terminal 2 in Damietta, Egypt, which is currently under construction.'
                        },
                        {
                            id: 105,
                            text: '"By acquiring a stake in JMBPL, Hapag-Lloyd is strengthening its presence in the attractive growth market of India while expanding its terminal and infrastructure business at the same time,"'
                        },
                        {
                            id: 106,
                            text: 'Hapag-Lloyd noted that despite closing the acquisition, the contracting parties agreed not to disclose any financial details of the deal.'
                        },
                        {
                            id: 107,
                            text: 'Hapag-Lloyd earlier said the acquisition would strengthen the shipping giant\'s presence in key strategic growth markets in India.'
                        },
                    ],
                    img_url: [{url: require('../../assets/img/home_hnews9_1.png')}]
                },
                {
                    key: 10,
                    title: 'Sun has risen, Flag  fluttering, Chinese Community Party has established for 74 years in the year of 2023! ',
                    time: '2023.09',
                    content: [
                        {
                            id: 108,
                            text: '74 years ago, the red sun rises from the east, the national flag flies, the People\'s Republic of China is founded. Through time, the original intention is keep beating in our heart, encouraging us forge ahead with confidence and fortitude. Hymn after hymn is singing about our great motherland with all the best wishes. Now, in this special moment, let’s celebrate the National Day, love our China, and may our country enjoy prosperity and brighter future.'
                        }
                    ],
                    img_url: [{url: require('../../assets/img/2023-gq1.jpg')}, {url: require('../../assets/img/2023-gq2.jpg')}]
                },
                {
                    key: 11,
                    title: 'Happy NEW YEAR',
                    time: '2024.02',
                    content: [],
                    img_url: [{url: require('../../assets/img/xnkl.jpg')}]
                }
            ],
        }
    }
}
</script>
<style lang="less">
.industry_trends_child_en_div {
  .industry_trends_content_div {
    .return_box {
      margin: 50px 0 50px 0;
      cursor: pointer;

      span {
        font-size: 14px;
      }
    }

    .industry_trends_content_box {
      line-height: 50px;
      padding: 0 0 80px 0;

      .title_p {
        font-size: 20px;
        font-weight: 700;
        text-align: center;
      }

      .time_from_div {
        text-align: center;

        .time_span {
          font-size: 16px;
          //margin: 0 100px 0 0;
          // display: inline-block;
        }

        .from_span {
          margin-left: 50px;

          font-size: 16px;
        }
      }

      .paragraph_div {
        width: 80%;
        margin: 0 auto;

        p {
          text-indent: 24px
        }
      }

      .news_img_div {
        width: 100%;
        margin: 30px auto 0px;

        div {
          margin-bottom: 10px;
          text-align: center;

          img {
            //height: 350px;
            width: 50%;
            display: inline-block;
          }
        }
      }

    }
  }
}

@media screen and (max-width: 900px) {
  .industry_trends_child_en_div {
    .industry_trends_content_div {
      padding: 0 15px;

      .return_box {
        padding-left: 0px;
      }

      .industry_trends_content_box {
        .news_img_div {
          width: 100%;
          height: auto;

          div {
            img {
              height: auto !important;
              width: 100%;
            }
          }
        }
      }
    }
  }
}
</style>
